<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else class="mt-6">
      <vs-row class="mb-3">
        <h3>{{ brand[`name_${$i18n.locale}`] }}</h3>
        <router-link v-if="$acl.check('admin')" :to="{name: 'brandEdit', params: {id: brand.uuid}}" class="p-3 mb-4 mr-4 ml-auto rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
          <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-primary">{{$t('edit')}}</span>
        </router-link>
        <div v-if="$acl.check('admin')" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(brand.uuid)">
          <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base">{{$t('delete')}}</span>
        </div>
      </vs-row>
      <vs-table stripe :data="Object.keys(brand)">
        <template slot="thead">
          <vs-th>{{$t('property')}}</vs-th>
          <vs-th>{{$t('value')}}</vs-th>
        </template>
        <template>
          <vs-tr v-if="brand.image_isAdded">
            <vs-td class="uppercase font-medium">{{ $t('props.image') }}</vs-td>
            <vs-td>
              <div class="flex flex-row overflow-x-auto" style="width: 100%;">
                <div class="img-container relative">
                  <img :src="`${$baseUrl}/brands/${brand.uuid}.webp`" alt="" class="h-40">
                </div>
              </div>
            </vs-td>
          </vs-tr>
          <vs-tr v-for="(prop, idx) in Object.keys(brand)" :key="idx">
            <vs-td>
              <span class="uppercase font-medium">{{$t(`props.${prop}`)}}</span>
            </vs-td>
            <vs-td v-if="brand[prop] === true || brand[prop] === false">
              <vs-chip :color="brand[prop] ? 'success' : 'danger'">
                <feather-icon v-if="brand[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td v-else-if="prop === 'brand_categories'">
              <router-link v-for="category in brand.brand_categories" :key="category.id" :to="`/categories/${category.uuid}`" class="font-medium">{{ category[`name_${$i18n.locale}`] }}, </router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt'">
              {{ new Date(brand[prop]).toLocaleString('ru-RU') }}
            </vs-td>
            <vs-td v-else>
              {{ brand[prop] }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return { brand: {}, isLoading: true }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get(`/brands/${this.$route.params.id}`).then(response => {
        this.brand = response.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.notify('Error', this.$t('notify.error'), err.response.data.message)
      })
    },
    deleteData (id) {
      this.$http.delete(`/brands/delete/${id}`).then(() => {
        this.notify('Success', this.$t('notify.success'), `${this.$t('brand')} ${this.$t('notify.sDeleted')}`)
        this.$router.push('/brands')
      }).catch(err => {
        this.notify('Error', this.$t('notify.error'), err.response.data.message)
      })
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>